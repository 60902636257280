import React, { useState } from "react";
import Header from "./Header";
import SimpleHeader from "./SimpleHeader";
import Footer from "./Footer";
import MobileMenu from "./MobileMenu";
import styled from "styled-components";
import Block from "./Block";
import HeaderV2 from "./brandingV2/HeaderV2";

const Wrapper = styled.div`
`;


export default function Layout({
  children,
  noFooter,
  simpleHeader,
  showPhone,
  isLoggedIn,
  logOut,
  zadnaPodpora,

  customCtaText,
  customCtaLink,
  customLoginText,
  customLoginLink,
  subMenuItems,
  subMenuLong,
  forcePohrbyMenu,
}) {

  const [ jeOtevreneMenu, setJeOtevreneMenu] = useState(false);

  const otevritMenu = () => {
    if (jeOtevreneMenu) {
      setJeOtevreneMenu(false);
    }

    else {
      setJeOtevreneMenu(true);
    }
  }


  return (
    
    <div>
      {/* <Block />  */}

      {(simpleHeader) ?
        <SimpleHeader isLoggedIn={isLoggedIn} showPhone={showPhone} logOut={logOut} />
      : 
        <>
          <MobileMenu jeOtevreny={jeOtevreneMenu} otevritMenu={otevritMenu} forcePohrbyMenu={forcePohrbyMenu} />
          <HeaderV2
            otevritMenu={otevritMenu}
            customCtaText={customCtaText}
            customCtaLink={customCtaLink}
            customLoginText={customLoginText}
            customLoginLink={customLoginLink}
            subMenuItems={subMenuItems}
            subMenuLong={subMenuLong}
            zadnaPodpora={zadnaPodpora}
          />
        </>
      }
      <Wrapper>
        {children}
      </Wrapper>
      {(noFooter) ? null : <Footer />}
    </div>
  )
}
