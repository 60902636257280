import React, { useEffect, useState } from "react";
import { Link, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { useLocation } from '@reach/router';
import 'animate.css';
import arrowImg from "../../assets/img/arrow.svg";
import loginImg from "../../assets/img/user.svg";
import Logo from "../../assets/img/logo_new.svg";
import Telefon from "../../assets/img/telefon.svg";
import MenuIcon from "../../assets/img/menuIcon.svg";
import AvailabilityPanel from "./../brandingV2/AvailabilityPanel";
import navArrow from "../../assets/img/navArrow.svg";
import Img from "gatsby-image";
import AnchorLink from 'react-anchor-link-smooth-scroll';

import menuZaveti from "../../assets/img/menuZaveti.svg";
import menuArchiv from "../../assets/img/menuArchiv.svg";
import menuPrepaid from "../../assets/img/menuPrepaid.svg";
import menuPohrebka from "../../assets/img/menuPohrebka.svg";
import menuLokace from "../../assets/img/menuLokace.svg";
import menuParte from "../../assets/img/menuParte.svg";
import menuKostel from "../../assets/img/menuKostel.svg";
import menuRakev from "../../assets/img/menuRakev.svg";
import menuCare from "../../assets/img/menuCare.svg";
import menuPoradna from "../../assets/img/menuPoradna.svg";
import menuSlovnik from "../../assets/img/menuSlovnik.svg";


const HeaderInner = styled.header`
  width: calc(100%);
  margin: 0 auto;
  background: none;
  z-index: 10;
  background: #ffffff;

  .displayNone {
    display: none !important;
  }

  @media (min-width: 900px) {
    border-bottom: none;
  }

  .shadow {
    background: rgba(0,0,0,0.20);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    animation-duration: 0.5s;

    @media (max-width: 899px) {
      display: none;
    }
  }

  .hlavniMenu {
    padding: 15px 0;
    border-bottom: 1px solid #DEDEDE !important;
    background: #ffffff;
    z-index: 51;
    position: relative;
  }

  .headerWrapper {
    width: 100%;
    max-width: 1150px;
    width: calc(100% - 100px);
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 899px) {
      width: calc(100% - 50px);
    }

    .logo {
      display: block;

      img {
        display: block;
        height: 28px;
      }
    }

    .desktopHeader {
      width: calc(100% - 60px - 107px);
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 970px) {
        width: calc(100% - 40px - 107px);
      }

      @media (max-width: 899px) {
        display: none;
      }

      nav {
        display: flex;
      }

      .cta {
        display: flex !important;
        align-items: center !important;

        img {
          height: auto !important;
          position: static !important;
          margin: 0 !important;

          @media (max-width: 950px) {
            display: block !important;
          }
        }

        a {
          text-decoration: none !important;
          width: fit-content;
        }

        span {
          text-decoration: none !important;
          font-family: Visuelt-Medium !important;
          font-size: 16px !important;
        }

        .login {
          border: 2px solid #95A8C3;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 5px;
          padding: 5px 10px;
          margin-right: 15px;

          @media (max-width: 970px) {
            display: none
          }

          span {
            color: #394B5C;
            margin-left: 8px;

            @media (max-width: 1060px) {
              display: none
            }
          }

          img {
            height: 19px !important;
          }

          &:hover {
            background: #E2EBF8;
            cursor: pointer;
          }
        }

        .quote {
          padding: 7px 12px;
          background: #3b557c;
          border-radius: 5px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            color: #FFFFFF;
            margin-right: 8px;
          }

          &:hover {
            background: #2A4164;
            cursor: pointer;
          }
        }
      }
    }

    .mobileHeader {
      display: block;

      img {
        display: block;
        width: 25px;
      }

      @media (min-width: 900px) {
        display: none;
      }
    }
  }

  

  .submenu {
    border-bottom: 1px solid #DEDEDE !important;
    width: 100%;
    padding: 15px 0;
    margin: 0 auto;
    position: relative;
    background: #FFFFFF;
    
    @media (max-width: 899px) {
      display: none;
    }

    nav {
      max-width: 1150px;
      width: calc(100% - 100px);
      margin: 0 auto;
      position: relative;

      ul {
        li {
          display: inline-block;
          margin-right: 40px;

          a {
            font-family: Visuelt-Medium;
            font-size: 16px;
            color: #000000;
            opacity: 0.6;
            text-decoration: none;
            transition: opacity 0.2s ease-in-out;

            &:hover {
              border-bottom: 4px solid #DFDFDF;
              padding-bottom: 11px;
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .subMenuLong {
    @media (max-width: 1190px) {
      ul {
        li {
          margin-right: 30px !important;

          &:last-child {
            margin-right: 0 !important;
          }

          a {
            font-size: 14px !important;
          }
        }
      }
    }

    @media (max-width: 990px) {
      nav {
        width: calc(100% - 50px);
      }

      ul {
        li {
          margin-right: 20px !important;
        }
      }
    }
  }
`;

const NavMenuInner = styled.div`
  position: relative;
  
  @media (max-width: 1250px) {
    position: static;
  }

  .menuButton {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    margin-right: 50px;
    transition: opacity 0.2s ease-in-out;
    opacity: ${props => props.menuOpen ? "1" : "0.6"};

    @media (max-width: 1150px) {
      margin-right: 30px;
    }

    &:hover {
      opacity: 1;
    }

    span {
      font-family: Visuelt-Medium;
      font-size: 16px;
      color: #000000;
      display: block;
      margin-right: 9px;
    }

    img {
      display: block;
      transition: transform 0.3s ease-in-out;
      transform: ${props => props.menuOpen ? "rotate(180deg)" : "rotate(0deg)"};
    }
    
  }

  .menu {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    position: absolute;
    width: 600px;
    background: #FFFFFF;
    border-radius: 20px;
    background: #FFFFFF;
    box-shadow: 0 8px 15px 0 rgba(0,0,0,0.23);
    z-index: 100;
    top: 60px;
    overflow: hidden;
    animation-duration: 0.5s;
    animation: fadeInDown;
    left: 0;
    


    .heading {
      display: block;
      opacity: 0.5;
      font-family: Visuelt-Medium;
      font-size: 14px;
      color: #000000;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-bottom: 25px;
    }

    .left {
      height: 100%;

      li {
        a {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          .text {
            width: calc(100% - 42px);
          }
        }
      }
    }

    .right {
      height: 100%;
      background: #F4F3F0;
    }

    .menuInner {
      padding: 30px;

      .menuInnerNote {
        font-family: Visuelt-Regular;
          color: #2A2D32;
          font-size: 16px;

        a {
          color: #2A2D32;
          text-decoration: underline;
        }
      }

      ul {
        li {
          list-style: none;
          margin-top: 25px;
          
          a {
            text-decoration: none;

            .text {
              .name {
                font-family: Visuelt-Bold;
                font-size: 18px;
                color: #243A58;
                position: relative;

                &:before {
                  content: "";
                  position: absolute;
                  left: 0px;
                  bottom: -2px;
                  width: 100%;
                  height: 1px;
                  background-color: #243A58;
                  transform: scaleX(0);
                  transition: transform 0.2s ease-out 0s;
                }
              }

              p {
                font-family: Visuelt-Regular;
                font-size: 16px;
                color: rgba(0,0,0,0.70);
                margin-top: 5px;
              }
            }

            &:hover {
              .name {
                &:before {
                  transform: scaleX(1);
                  transition: transform 0.2s ease-in 0s;
                }
              }

              p {
                color: #2A2D32;
              }
            }
          }
        }

        .bubble {
          margin-top: 15px;

          a {
            position: relative;
            overflow: hidden;
            display: block;
            border-radius: 10px;
            width: 220px;
            height: 120px;
            box-shadow: 0 4px 10px 0 rgba(0,0,0,0.15);

            &:hover {
              cursor: pointer;

              .obrazek {
                transform: scale(1.1);
              }
            }

            .name {
              position: absolute;
              bottom: 15px;
              left: 15px;
              font-family: Visuelt-Bold;
              font-size: 18px;
              color: #FFFFFF;
              z-index: 1;
            }

            .obrazek {
              transition: all 0.3s ease-in-out;
            }
          }
        }
      }
    }
  }

  .odkazyNaMesta {
    display: block;
    margin-top: 15px;
    
    a {
      display: block;
      font-family: Visuelt-Regular;
      font-size: 14px;
      color: #243A58;
      margin-top: 3px;

      &:hover {
        text-decoration: underline !important;
      }
    }
  }
`;


const NavMenu = ({
  buttonText,
  leftHeading,
  rightHeading,
  leftItems,
  rightItems,
  isBubble,

  isActive,
  name,
  toggle,
}) => {
  

  return (
    <NavMenuInner menuOpen={isActive}>
      <button className="menuButton" onClick={() => toggle(name)}>
        <span>{buttonText}</span>
        <img src={navArrow} alt=""/>
      </button>
      <div className={"menu"} style={{
        transform: !isActive ? "translate3d(0, 100%, 0)" : "translate3d(0, 0, 0)",
        opacity: !isActive ? "0" : "1",
        transition: "all .5s",
        visibility: !isActive ? "hidden" : "visible",
      }}>
        <div className="left">
          <div className="menuInner">
            <span className="heading">{leftHeading}</span>
            <ul>
              {leftItems.map((item, index) => (
                <li key={index}>
                  <Link to={item.link}>
                    <img src={item.img} alt={item.name} />
                    <div className="text">
                      <span className="name">{item.name}</span>
                      <p>{item.p}</p>

                      {
                        item.link === "/pohrebni-sluzba/" &&
                          <div className="odkazyNaMesta">
                            <Link to="/pohrebni-sluzba-praha/">Pohřební služba Praha</Link>
                            <Link to="/pohrebni-sluzba-brno/">Pohřební služba Brno</Link>
                            <Link to="/pobocky/">Další města</Link>
                          </div>
                      }
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="right">
          <div className="menuInner">
            <span className="heading">{rightHeading}</span>
            {isBubble ?
              <ul>
                {rightItems.map((item, index) => (
                  <li key={index} className="bubble">
                    <Link to={item.link}>
                      <span className="name">{item.name}</span>
                      <Img className="obrazek" alt={item.name} fixed={item.img} />
                    </Link>
                  </li>
                ))}
                <li className="menuInnerNote">Nově i <Link to="/pravoslavny-pohreb">pravoslavné pohřby</Link></li>
              </ul>
            :
              <ul>{rightItems.map((item, index) => (
                <li key={index}>
                  <Link to={item.link}>
                    <div className="text">
                      <span className="name">{item.name}</span>
                      <p>{item.p}</p>
                    </div>
                  </Link>
                </li>
              ))}</ul>
            }
          </div>
        </div>
      </div>
    </NavMenuInner>
  );

}

const HeaderV2 = ({
  otevritMenu,
  customCtaText,
  customCtaLink,
  customLoginText,
  customLoginLink,
  subMenuItems,
  subMenuLong,
  zadnaPodpora,
}) => {

  const activeStyles = {
    "borderBottom": "4px solid #3A557C",
    "paddingBottom": "11px",
    "opacity": "1",
  }

  const [menuItemsState, setMenuItemsState] = useState({
    "plans": false,
    "funeral": false,
    "care": false,
    "support": false,
  });

  const [indexNumber, setIndexNumber] = useState(0);

  // check if one of the menuItemsState properties is true

  let isShadowActive = false;

  for (let property in menuItemsState) {

    if (menuItemsState[property] === true) {
      isShadowActive = true;
    }
  }

  const toggle = (name) => {

    let tempToggle = menuItemsState;
    let correctVal = !menuItemsState[name];

    
    for (let property in tempToggle) {
      tempToggle[property] = false;
    }

    tempToggle[name] = correctVal;

    setMenuItemsState(tempToggle);

    setIndexNumber(indexNumber + 1);
  }

  const data = useStaticQuery(graphql`
    query {
      bezObradu: file(relativePath: { eq: "bezObraduHeader.jpg" }) {
        childImageSharp {
          fixed(width: 220, height: 120) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
  
      sObradem: file(relativePath: { eq: "sObrademHeader.jpg" }) {
        childImageSharp {
          fixed(width: 220, height: 120) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
  
      netradicniPohreb: file(relativePath: { eq: "netradicniHeader.jpg" }) {
        childImageSharp {
          fixed(width: 220, height: 120) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
  
      miminka: file(relativePath: { eq: "miminkaHeader.jpg" }) {
        childImageSharp {
          fixed(width: 220, height: 120) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

  return (
    <>
      {!zadnaPodpora && 
        <AvailabilityPanel />
      }

      <HeaderInner>
        <div className="hlavniMenu">
          <div className="headerWrapper">
            <Link className="logo" to="/">
              <img loading="eager" src={Logo} alt="Logo Goodbye" />
            </Link>

            <div className="desktopHeader">
              <nav key={menuItemsState}>

                <NavMenu
                  name={"plans"}
                  toggle={toggle}
                  isActive={menuItemsState["plans"]}
                  menuItemsState={menuItemsState}

                  buttonText="Plánujte dopředu"
                  leftHeading={"Naše služby"}
                  rightHeading={"Důležité informace"}
                  isBubble={false}
                  leftItems={[
                    {
                      name: "Závěti",
                      p: "Sepište si plnohodnotnout závěť za 15 minut online",
                      link: "/zaveti/",
                      img: menuZaveti,
                    },
                    {
                      name: "Digitální archiv",
                      p: "Bezpečně uschováme vaše dokumenty, které po smrti pošleme vybraným lidem",
                      link: "/digitalni-archiv/",
                      img: menuArchiv,
                    },
                    {
                      name: "Předplacený pohřeb",
                      p: "Plánujte dopředu a ušetřete nejbližším starosti a stres z plánování pohřbu",
                      link: "/predplaceny-pohreb/",
                      img: menuPrepaid,
                    },
                  ]}
                  rightItems={[
                    {
                      name: "Seznam hospiců",
                      p: "Přehledný seznam všech hospiců, LDN, agentur domácí péče a ostatních paliativních služeb",
                      link: "/seznam-hospicu/",
                    },
                  ]}
                />

                <NavMenu
                  name={"funeral"}
                  toggle={toggle}
                  isActive={menuItemsState["funeral"]}
                  menuItemsState={menuItemsState}

                  buttonText="Pohřební služba"
                  leftHeading={"Pohřební služba"}
                  rightHeading={"Varianty pohřbu"}
                  isBubble={true}
                  leftItems={[
                    {
                      name: "Pohřební služba",
                      p: "Naplánujte jedinečný pohřeb z domova, za předem stanovenou cenu",
                      link: "/pohrebni-sluzba/",
                      img: menuPohrebka,
                    },
                    {
                      name: "Kde fungujeme?",
                      p: "Aktuálně naše pohřební služba funguje v 27 městech po celé ČR",
                      link: "/pobocky/",
                      img: menuLokace,
                    },
                    {
                      name: "Online parte",
                      p: "Vytvořte si interaktivní smuteční oznámení zdarma",
                      link: "/parte/",
                      img: menuParte,
                    },
                    {
                      name: "Obřadní síně",
                      p: "Seznam všech obřadních síní a smutečních místností",
                      link: "/obradni-sine/",
                      img: menuKostel,
                    },
                    {
                      name: "Rakve na pohřeb",
                      p: "Nabídka rakví v našich regionech",
                      link: "/rakve/",
                      img: menuRakev,
                    },
                  ]}
                  rightItems={[
                    {
                      name: "Kremace bez obřadu",
                      link: "/kremace-bez-obradu/",
                      img: data.bezObradu.childImageSharp.fixed,
                    },
                    {
                      name: "Pohřeb s obřadem",
                      link: "/pohreb-s-obradem/",
                      img: data.sObradem.childImageSharp.fixed,
                    },
                    {
                      name: "Netradiční pohřeb",
                      link: "/netradicni-pohreb/",
                      img: data.netradicniPohreb.childImageSharp.fixed,
                    },
                    {
                      name: "Pohřeb pro dítě",
                      link: "/pohreb-miminka/",
                      img: data.miminka.childImageSharp.fixed,
                    },
                  ]}
                />

                
                <NavMenu
                  name={"support"}
                  toggle={toggle}
                  isActive={menuItemsState["support"]}
                  menuItemsState={menuItemsState}

                  buttonText="Podpora"
                  leftHeading={"Podpora pro pozůstalé"}
                  rightHeading={"O firmě"}
                  leftItems={[
                    {
                      name: "Goodbye Care",
                      p: "Asistenční služba pro firmy a pojištovny",
                      link: "/care/",
                      img: menuCare,
                    },
                    {
                      name: "Poradna",
                      p: "Připravili jsem pro vás desítky článků. Všechny informace na jednom místě",
                      link: "/poradna/",
                      img: menuPoradna,
                    },
                    {
                      name: "Slovník pojmů",
                      p: "Vysvětlení pojmů, se kterými se můžete setkat",
                      link: "/slovnik-pojmu/",
                      img: menuSlovnik,
                    },
                  ]}
                  rightItems={[
                    {
                      name: "O nás",
                      link: "/o-nas/",
                      p: "Podívejte se, kdo stojí za společností Goodbye a přečtěte si náš příběh",
                    },
                  ]}
                />
              </nav>

              <div className="cta">
                <Link className="login" to={customLoginLink ? customLoginLink : "/prihlasit-se/"}>
                  <img src={loginImg} alt="" />
                  <span>{customLoginText ? customLoginText : "Přihlásit se"}</span>
                </Link>

                <Link className="quote" to={customCtaLink ? customCtaLink : "/nabidka/dotaznik/"}>
                  <span>{customCtaText ? customCtaText : "Nabídka ZDARMA"}</span>
                  <img src={arrowImg} alt="" />
                </Link>
              </div>

            </div>

            <div onClick={() => otevritMenu()} onKeyDown={() => otevritMenu()} className="mobileHeader" role="button" tabIndex={0}>
              <img src={MenuIcon} alt="Menu" />
            </div>
          </div>
        </div>
        {subMenuItems &&
          <div className={subMenuLong ? "submenu subMenuLong" : "submenu"}>
            <nav>
              <ul>
                {subMenuItems.map((item) => {

                  if (item.url.startsWith('#')) {
                    return (
                      <li key={item.url}>
                        <AnchorLink  offset='60' href={item.url}>{item.name}</AnchorLink>
                      </li>
                    )
                  }
                  
                  else {
                    return (
                      <li key={item.url}>
                        <Link activeStyle={activeStyles} to={item.url}>{item.name}</Link>
                      </li>
                    )}
                  }

                  
                  
                )}






              </ul>
            </nav>
          </div>
        }
        
        
        <div className={"shadow"}
          style={{
            opacity: !isShadowActive ? "0" : "1",
            transition: "all .5s",
            visibility: !isShadowActive ? "hidden" : "visible",
          }}
        ></div>

      </HeaderInner>
    </>
    
  )
}




export default HeaderV2;
